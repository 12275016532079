@media (max-width: 1380px) {
    .edit_profile .profile-avatar {
        width: 33%;
        padding: 10px;
    }
}

@media (max-width: 1199px) {
    .edit_profile .profile-avatar-wrapper {
        max-width: 160px;
    }

    .edit_profile .profile-avatar {
        width: 50%;
        padding: 10px;
    }

    .resp_overview .social-card h2 {
        font-size: 20px;
    }

    .resp_overview .social-card i {
        font-size: 25px;
    }

    .custom_hashtag_template .custom_h1_hashtag {
        font-size: 70px !important;
    }
}

@media (max-width: 991px) {
    .auth-wrapper .auth_logo img {
        width: 150px;
        height: auto;
    }

    .resp_overview .social-card p {
        font-size: 14px;
    }

    .pcoded-header .m-header {
        justify-content: center;
    }
}

@media (max-width: 767px) {
    .edit_profile .profile-avatar-wrapper {
        max-width: 320px;
    }

    .edit_profile .profile-avatar {
        width: 25%;
        padding: 10px;
    }

    .clients-list .data-table-extensions>.data-table-extensions-filter {
        width: 100%
    }

    .clients-list .data-table-extensions {
        position: initial;
        width: 100%;
        margin-bottom: 15px;
    }

    .clients-list .data-table-extensions>.data-table-extensions-filter>.filter-text {
        width: 100%;
    }

    .data-table-extensions>.data-table-extensions-filter>.icon {
        position: absolute;
        left: 13px;
    }

    .campaign_dashboard .custom_value_influencers {
        margin-right: 0;
    }

    .campaign_dashboard .custom_top_share {
        margin-right: 0;
    }
}

@media (max-width: 576px) {
    .auth-wrapper .auth-content:not(.container) {
        width: auto;
    }

    .campaign-tile {
        height: 130px;
    }
}

@media (max-width: 420px) {
    .edit_profile .profile-avatar-wrapper {
        max-width: 240px;
    }

    .edit_profile .profile-avatar {
        width: 33%;
        padding: 10px;
    }

    .campaign-tile {
        height: 100px;
    }
}
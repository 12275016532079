body,
p {
    font-size: 14px;
}

.pcoded-header[class*=header-] .btn-link {
    color: #fff;
    font-size: 20px;
}

.dashboard-bg {
    background: #ECF0F5;
}

.auth_logo img {
    max-width: 250px;
}

.invalid-feedback {
    font-size: 100%;
    margin-bottom: 10px;
}

.password-suggestion{
    border: 1px solid var(--cadmiumOrange);
    border-bottom: 5px solid var(--cadmiumOrange);
    border-radius: 5px;
    max-width: 210px;
    user-select: none;
}

.password-suggestion .password-suggestion-heading {
   font-size: 10px;
   background-color: var(--cadmiumOrange);
   color: #fff;
   margin-bottom: -2px;
    margin-left: -1px;
    border-bottom-left-radius: 2px;
    height: 24px;
    border-top-right-radius: 3px;
}

.password-suggestion .password-suggestion-heading:after {
    content: '';
    position: absolute;
    top: 0px;
    right: -22px;
    border-top: 12px solid transparent;
    border-left: 12px solid var(--cadmiumOrange);
    border-right: 12px solid transparent;
    border-bottom: 12px solid var(--cadmiumOrange);
    height: 24px;
    width: 24px;
}

.modal-xxl {
    max-width: 90vw;
}

.modal-header {
    background: var(--royalBlueDark);
    padding: 12px 20px;
}

.modal-title {
    color: #fff !important;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
}

.modal-footer {
    border-top: none;
    padding: 0 20px 20px 20px;
}

.btn {
    padding: 10px 30px;
    font-weight: 600;
    border-radius: 31px;
    color: #FFFFFF;
}

.btn-icon {
    display: inline-block;
    font-weight: 400;
    color: white;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 0.875rem;
    border-radius: 20px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 0.25rem 0.5rem;
}

.btn_primary {
    background: var(--blueJeans) !important;
    border: 1px solid var(--blueJeans) !important;
}

.btn_primary:hover {
    opacity: .90;
    color: #fff;
}

.btn_secondary {
    background: var(--royalBlueDark);
    border: 1px solid var(--royalBlueDark);
}

.btn_secondary:hover {
    opacity: .90;
    color: #fff;
}

.btn_tertiary {
    background: var(--cadmiumOrange);
    border: 1px solid var(--cadmiumOrange);
}

.btn_tertiary:hover {
    opacity: .90;
    color: white;
}

.btn_danger {
    background: var(--deleteColor);
    border: 1px solid var(--deleteColor);
}

.btn_danger:hover {
    opacity: .90;
    color: #fff;
}

.btn-disable {
    opacity: .70;
    cursor: not-allowed;
}

.user-card .cover-img-block .change-cover .dropdown-toggle {
    background: var(--blueJeans);
}

/*===================== BTN ===============*/
.view_all_plans .data-table-extensions>.data-table-extensions-filter {
    margin-right: 0 !important;
}

.ps__thumb-y {
    background-color: var(--blueJeans) !important;
}

.ps__rail-y:hover>.ps__thumb-y {
    background: var(--neutralNavy) !important;
}

.form-control:focus {
    background-color: transparent;
}

.was-validated .form-control.form-pw:invalid,
.form-control.form-pw.is-invalid,
.was-validated .form-control.form-pw:valid,
.form-control.form-pw.is-valid {
    background-image: url('');
}

.form-label,
label {
    color: var(--royalBlueDark);
    font-weight: 700;
    font-size: 16px;
}

.card-header h5 {
    font-family: 'Audiowide';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 30px !important;
    color: var(--royalBlueDark) !important;
}

.css-yk16xz-control {
    background-color: transparent;
}

.form-group {
    margin-bottom: 1.5rem;
}

.add-image-container {
    border: 1px solid;
}

.modal-header {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.modal-footer {
    padding: 20px;
}

.profile_dropdown_wrapper .custom_bg_burnt_orange {
    background-color: var(--cadmiumOrange) !important;
}

.profile_dropdown_wrapper .dropdown .profile-notification .pro-body li:hover {
    background-color: var(--royalBlueDark);
    border-radius: 22px;
}

.profile_dropdown_wrapper .dropdown .profile-notification .pro-body li a {
    font-weight: 400;
    font-size: 14px;
    color: var(--royalBlueDark);
    font-style: normal;
}

.profile_dropdown_wrapper .dropdown .dropdown-menu {
    border-radius: 15px;
}

.admin-profile {
    background: #ECF0F5;
    border-radius: 15px;
}

.user-card .change-profile .profile-dp {
    width: 150px;
    height: 150px;
    border-radius: 110px;
    background: white;
}
.user-card .change-profile .profile-dp .overlay {
    opacity: 1;
    background: none;
}
.card .card-header {
    border: none;
}

.change-password-btn {
    position: absolute;
    right: 0;
    bottom: 16px;
}


/*profile page*/
.profile_parent .card.user-card {
    border-radius: 15px;
}

.profile_parent .user_cover_img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 180px;
    border-radius: 15px 15px 0 0 !important;
}

.profile_parent .user-card .user-about-block {
    margin-top: -80px;
}

.profile_parent .user-card .change-profile .profile-dp {
    width: 150px;
    height: 150px;
}

.profile-overlay-bg .profile-dp .overlay span {
    background: none !important;
    border-top: none !important;
}

.change-profile .profile-dp .camera-icon {
    width: 35px;
    height: 35px;
    bottom: 0;
    padding: 4px;
    border-radius: 20px;
    background: var(--blueJeans);
    color: white;
    font-size: 18px;
    opacity: 0.9;
}

.card {
    border-radius: 15px;
}

.profile_parent .campaign_follower {
    background-color: var(--dashBoardBg);
    border-radius: 15px;
    padding: 11px 49px;
    margin-top: 20px;
}

.profile_parent .campaign_follower p {
    margin-bottom: 0;
}

.profile_parent .campaign_follower div.flex-column {
    align-items: center;
}

.profile_parent .campaign_follower .value_font_style {
    font-weight: 600;
    font-size: 18px;
    color: var(--royalBlueDark);
}

.profile_parent .campaign_follower .text_font_style {
    font-weight: 400;
    font-size: 12px;
    color: var(--royalBlueDark);
}

.profile_parent .custom_text_profile {
    background-color: var(--dashBoardBg);
    border-radius: 15px;
    margin-top: 20px;
    padding: 10px;
}

.profile_parent .custom_text_profile p {
    font-weight: 400;
    font-size: 13px;
    color: var(--navInactive);
    margin-bottom: 0;
}

.profile_parent .card.card_tabs {
    border-radius: 15px;
}

.nav_position {
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    font-style: normal;
}

.profile_parent .nav-tabs.nav_position .nav-link.active {
    background: var(--blueJeans);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.20);
    border-radius: 32px;
    border-bottom: none;
    padding: 10px;
}

.profile_parent .nav-tabs {
    border-bottom: none;
}

.profile_parent .nav-tabs.nav_position .nav-link {
    background: var(--navInactive);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 32px;
    padding: 10px
}

.profile_parent .nav-item:not(:last-of-type) {
    margin-right: 30px;
}

.profile_parent .sector_interests .bg-interest {
    background: var(--emerald);
    color: #FFFFFF;
    border-radius: 29px;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    font-style: normal;
}

.profile_parent .sector_interests .bg-inactive {
    outline: 1px solid;
    outline-color: var(--emerald);
    color: #FFFFFF;
    border-radius: 29px;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    font-style: normal;
}

.profile_parent .campaign-tile {
    border-radius: 15px;
}

.profile_parent .campaign-tile a img {
    border-radius: 15px;
}

.profile_parent.custom_profile_wraper_row {
    display: flex;
    flex-wrap: wrap;
}

.profile_parent .custom_Profile_cards {
    height: 100%;
}

.profile_parent .table td {
    border: none;
}

.profile_parent .connection_name {
    font-weight: 500;
    font-size: 14px;
    color: var(--royalBlueDark);
}

.profile_parent .connection_bio {
    font-weight: 400;
    font-size: 13px;
    color: var(--navInactive);
}

.profile_parent .connection_status {
    font-weight: 500;
    font-size: 14px;
    color: var(--deleteColor);
}

.overview-gutter {
    margin-left: -10px;
    margin-right: -10px;
}

.overview-gutter [class^="col"] {
    padding-left: 10px;
    padding-right: 10px
}


/*end profile page*/


/*================================ roles & permissions ================================*/
.roles-permissions .theader-style {
    background: var(--blueJeans);
    color: #fff;
    text-transform: uppercase;
}

.roles-permissions .nav-pills .left-tab-header .nav-link {
    background: var(--blueJeans);
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    border-radius: 15px 15px 0px 0px;
    height: 50px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
}

.roles-permissions .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: var(--cadmiumOrange);
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    border-radius: 0;
}

.roles-permissions .nav-pills .nav-link {
    background: var(--dashBoardBg);
    border-bottom: 1px solid #e2e5e8;
    border-radius: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: var(--royalBlueDark);
    height: 50px;
}

.roles-permissions .action-label {
    color: var(--royalBlueDark);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    border-right: 1px solid var(--silverGrey);
}

.roles-permissions .theader-style:first-child {
    border-radius: 15px 0 0 0;
    vertical-align: middle;
    border-right: 1px solid var(--silverGrey);
}

.roles-permissions .theader-style:last-child {
    border-radius: 0 15px 0 0;
    vertical-align: middle;
}

.roles-permissions tbody {
    background-color: var(--dashBoardBg);
}

.roles-permissions tbody tr {
    height: 50px;
}

.roles-permissions .table-card.latest-activity-card {
    background-color: var(--dashBoardBg);
    box-shadow: none;
}

.roles-permissions .table-card.latest-activity-card .card-footer {
    border-radius: 15px;
}

.roles-permissions .switch input[type=checkbox]+.cr {
    top: 5px;
}

.roles-permissions .switch input[type=checkbox]:checked+.cr:before {
    background: rgba(60, 166, 50, 0.5);
    transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}

.roles-permissions .switch input[type=checkbox]+.cr:after {
    background: var(--cadmiumOrange);
}

.roles-permissions .switch input[type=checkbox]:checked+.cr:after {
    background: var(--greenPantone);
}

.roles-permissions .switch input[type=checkbox]:checked+.cr .disable-allow:before {
    background: var(--navInactive);
    transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}

.roles-permissions .form-group label.not-allow {
    color: var(--cadmiumOrange);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
}

.roles-permissions .table.table-sm.table-hover {
    margin-bottom: 0;
}

.roles-permissions .table thead {
    height: 50px;
}

.roles-permissions .nav-pills .nav-item:last-child .nav-link {
    border-radius: 0 0 15px 15px;
}

.roles-permissions .add_roles_btn {
    background-color: var(--cadmiumOrange);
    border: none;
    border-radius: 38px;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
}

.roles-permissions .close {
    font-weight: 300;
    text-shadow: none;
    opacity: 1;
}

.roles-permissions .nav-link.active .close {
    color: white;
}

/*================================ roles & permissions ================================*/
.roles-permissions .theader-style {
    background: var(--blueJeans);
    color: #fff;
    text-transform: uppercase;
}

.roles-permissions .nav-pills .left-tab-header .nav-link {
    background: var(--blueJeans);
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    border-radius: 15px 15px 0px 0px;
    height: 50px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
}

.roles-permissions .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: var(--cadmiumOrange);
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    border-radius: 0;
}

.roles-permissions .nav-pills .nav-link {
    background: var(--dashBoardBg);
    border-bottom: 1px solid #e2e5e8;
    border-radius: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: var(--royalBlueDark);
    height: 50px;
}

.roles-permissions .action-label {
    color: var(--royalBlueDark);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    border-right: 1px solid var(--silverGrey);
}

.roles-permissions .theader-style:first-child {
    border-radius: 15px 0 0 0;
    vertical-align: middle;
    border-right: 1px solid var(--silverGrey);
}

.roles-permissions .theader-style:last-child {
    border-radius: 0 15px 0 0;
    vertical-align: middle;
}

.roles-permissions tbody {
    background-color: var(--dashBoardBg);
}

.roles-permissions tbody tr {
    height: 50px;
}

.roles-permissions .table-card.latest-activity-card {
    border-radius: 15px;
    background-color: var(--dashBoardBg);
    box-shadow: none;
}

.roles-permissions .switch input[type=checkbox]+.cr {
    top: 5px;
}

.roles-permissions .switch input[type=checkbox]:checked+.cr:before {
    background: rgba(60, 166, 50, 0.5);
    transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}

.roles-permissions .switch input[type=checkbox]+.cr:after {
    background: var(--cadmiumOrange);
}

.roles-permissions .switch input[type=checkbox]:checked+.cr:after {
    background: var(--greenPantone);
}

.roles-permissions .switch input[type=checkbox]:checked+.cr .disable-allow:before {
    background: var(--navInactive);
    transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}

.roles-permissions .form-group label.not-allow {
    color: var(--cadmiumOrange);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
}

.roles-permissions .table.table-sm.table-hover {
    margin-bottom: 0;
}

.roles-permissions .table thead {
    height: 50px;
}

.roles-permissions .roles_save_btn {
    background-color: var(--cadmiumOrange);
    border: none;
    border-radius: 29px;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
}

.roles-permissions .roles_save_btn_margin {
    padding: 15px;
    border-top: 1px solid #e2e5e8;
}

.roles-permissions .nav-pills .nav-item:last-child .nav-link {
    border-radius: 0 0 15px 15px;
}

/*================================ updated roles & permissions ================================*/
.roles-permissions.card {
    border-radius: 15px;
}

.roles-permissions.card .card-header {
    border: none;
}

.roles-permissions .card-header-pills {
    margin: initial;
}

/*================================ Campaign Dashboard ================================*/
.campaign_dashboard .card_top_row {
    width: 100%;
    height: auto;
}

.campaign_dashboard .card_bottom_rows {
    width: 100%;
    height: auto;
}

.campaign_dashboard .card_avg_time {
    width: 100%;
    background-color: var(--atomicTangerine);
}

.campaign_dashboard .title_cards {
    font-size: 24px !important;
}

.campaign_dashboard .cards_text {
    font-style: normal;
    color: #fff;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 16px;
}

.campaign_dashboard .card_text_value_lg {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    margin-bottom: 0;
}

.campaign_dashboard .card_text_value {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
}

.campaign_dashboard .text_secondary {
    font-style: normal;
    color: var(--royalBlueDark);
    margin-bottom: 0;
    font-weight: 600;
    font-size: 12px;
}

.campaign_dashboard .text_secondary_margin {
    margin-top: 35px;
}

.campaign_dashboard .text_secondary_margin_engagement {
    margin-top: 51px;
    margin-bottom: 10px;
}

.campaign_dashboard .ul_hr {
    background-color: #fff;
}

.campaign_dashboard .text_avg_time {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    margin-bottom: 0;
}

.campaign_dashboard .value_avg_time {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 0;
}

.custom_select_form {
    float: right;
}

.campaign_dashboard .custom_select_form .form-control {
    width: 178px;
    background: #FFFFFF;
    border: 0.5px solid var(--silverGrey);
    box-sizing: border-box;
    border-radius: 15px;
    height: 29px;
    padding: 0 0 0 7px;
    font-weight: 600;
    font-size: 16px;
    color: var(--royalBlueDark);
}

.campaign_dashboard .custom_select_form .form-control option {
    font-weight: 600;
    font-size: 16px;
    color: var(--royalBlueDark);
}

.campaign_dashboard .custom_select_form .form-group {
    margin-bottom: 0;
}

.campaign_dashboard .custom_select_form select.form-control.is-valid {
    padding-right: 2rem !important;
}

.campaign_dashboard .custom_select_form .form-control.is-valid:focus {
    border: 1px solid var(--royalBlueDark);
}

.custom_card_header .card .card-header {
    border-bottom: none;
}

.campaign_dashboard .custom_reach_radialbar .apexcharts-canvas {
    width: 150px !important;
    height: 150px !important;
}

.campaign_dashboard .custom_goals_subtitle {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 5px;
}

.campaign_dashboard .custom_goals_value {
    font-weight: 600;
    font-size: 16px;
    color: var(--royalBlueDark);
    margin-bottom: 0;
}

.campaign_dashboard .custom_reach_text {
    font-weight: 400;
    font-size: 12px;
    color: #72777A;
    margin-bottom: 0;
}

.campaign_dashboard .twitter_btn {
    background: #1DA1F2;
    box-shadow: 0px 4px 32px rgb(0 0 0 / 20%);
    color: #FFFFFF;
    border-radius: 34px;
    font-weight: 600;
    font-size: 23px;
}

.campaign_dashboard .whatsapp_btn {
    background: #25D366;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
    border-radius: 34px;
    font-weight: 600;
    font-size: 23px;
    color: #FFFFFF;
}

.campaign_dashboard .linkedin_btn {
    background: #0A66C2;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
    border-radius: 34px;
    font-weight: 600;
    font-size: 23px;
    color: #FFFFFF;
}

.campaign_dashboard .fb_btn {
    background: #1877F2;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
    border-radius: 34px;
    font-weight: 600;
    font-size: 23px;
    color: #FFFFFF;
}

.campaign_dashboard .mail_btn {
    background: var(--atomicTangerine);
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
    border-radius: 34px;
    font-weight: 600;
    font-size: 23px;
    color: #FFFFFF;
}

.campaign_dashboard .messenger_btn {
    background: #0099FF;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
    border-radius: 34px;
    font-weight: 600;
    font-size: 23px;
    color: #FFFFFF;
}

.campaign_dashboard .custom_btn_parent button {
    width: 100%;
}

.campaign_dashboard .custom_btn_padding i {
    margin-right: 20px;
}

.campaign_dashboard .custom_top_share {
    font-weight: 600;
    font-size: 14px;
    color: var(--blueJeans);
    float: right;
}

.custom_p_margin p {
    margin-bottom: 0;
}

.campaign_dashboard .custom_serial_number {
    font-weight: 600;
    font-size: 18px;
    color: var(--blueJeans);
    margin-right: 15px;
}

.campaign_dashboard .custom_name_influencers {
    font-weight: 500;
    font-size: 16px;
    color: var(--neutralNavy);
    margin-left: 10px;
    margin-right: 10px;
}

.campaign_dashboard .custom_value_influencers {
    font-weight: 500;
    font-size: 16px;
    color: var(--neutralNavy);
    margin-bottom: 0;
}

.campaign_dashboard .custom_view_all_link {
    font-weight: 400;
    font-size: 14px;
    text-decoration-line: underline;
    color: var(--blueJeans);
    float: right;
    padding-bottom: 12px;
}

.campaign_dashboard .custom_gender_text {
    font-weight: 600;
    font-size: 20px;
    color: var(--royalBlueDark);
    margin-bottom: 24px;
}

.campaign_dashboard .custom_img_position {
    display: block;
    margin: 0 auto;
}

.campaign_dashboard .custom_progress_bar1 .progress-bar {
    background-color: var(--emerald);
}

.campaign_dashboard .custom_progress_bar2 .progress-bar {
    background-color: var(--cadmiumOrange);
}

.campaign_dashboard .custom_progress_bar3 .progress-bar {
    background-color: var(--blueJeans);
}

.campaign_dashboard .custom_top_interests {
    font-weight: 600;
    font-size: 18px;
    color: var(--blueJeans);
    margin-bottom: 0;
}

.campaign_dashboard .custom_name_interests {
    font-weight: 400;
    font-size: 16px;
    color: var(--neutralNavy);
    margin-left: 15px;
}

.campaign_dashboard .custom_location_map.card svg {
    height: 218px;
    display: block;
    margin: 0 auto;
}

.campaign_dashboard .custom_goals_text_margin {
    margin-left: 24px;
}

.campaign_dashboard .goals_radialbar .custom_radialbar {
    width: 100px;
}

.campaign_dashboard .goals_radialbar .CircularProgressbar .CircularProgressbar-text {
    font-weight: 700;
    font-size: 26px;
    color: #024799;
}

p.text-error {
    color: red !important;
}

/*================================ Updated Campaign Dashboard ================================*/
.campaign_dashboard .card {
    border-radius: 15px;
}

.campaign_dashboard.card {
    border-radius: 15px;
}

.campaign_dashboard .reach_card {
    background-color: var(--blueJeans);
}

.campaign_dashboard .engagement_card {
    background-color: var(--greenPantone);
}

.campaign_dashboard .shares_card {
    background-color: var(--goldMetallic);
}

.campaign_dashboard .revenue_card {
    background-color: var(--emerald);
}

.campaign_dashboard .title_top_cards {
    font-size: 24px !important;
    color: #fff !important;
    margin-bottom: 0;
}

.campaign_dashboard .custom_progress_bar1 p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 18px;
    color: var(--royalBlueDark);
    text-align: center;
}

.campaign_dashboard .top_inetrests_wrapper img {
    width: 14px;
}

.campaign_dashboard .custom_campaign_hub_redirect {
    font-weight: 600;
    font-size: 18px;
    color: var(--majorelleBlue);
}

.campaign_dashboard .campign_hub_wrapper i {
    font-size: 22px;
    color: var(--majorelleBlue);
}

.campaign_dashboard .campaign_album {
    font-weight: 600;
    font-size: 18px;
    color: var(--royalBlueDark);
}

.goals_radialbar .text1 {
    color: var(--blueJeans);
}

.goals_radialbar .text2 {
    color: var(--goldMetallic);
}

.goals_radialbar .text3 {
    color: var(--greenPantone);
}

.goals_radialbar .text4 {
    color: var(--emerald);
}

.custom_mb {
    margin-bottom: 30px !important;
}


.add-coupon-modal .react-datepicker__input-container input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #000;
    padding: 0.625rem 0;
}

.add-coupon-modal .custom_names {
    width: 100%;
    height: 126px;
    border-radius: 15px;
    overflow-y: auto;
    background: var(--dashBoardBg);
    padding: 10px;
}

.add-coupon-modal .custom_names span {
    background: var(--blueJeans);
    padding: 5px 10px;
    color: #fff;
    border-radius: 29px;
    margin-left: 5px;
    margin-bottom: 5px;
    display: inline-block;
}

.add-coupon-modal .custom_names span .fa-times {
    color: red;
    margin-left: 4px;
    cursor: pointer;
}

.fan_list .data-table-extensions>.data-table-extensions-filter {
    margin-right: 0 !important
}

.notifications_card .iAwKFK:not(:last-of-type) {
    border-bottom: none !important;
    padding: 5px;
    font-style: normal;
}

.notifications_card .bhoXfZ {
    border-top: none !important;
}

.notifications_card .iAwKFK {
    padding: 5px;
    font-style: normal;
}

.notifications_card .iAwKFK:hover {
    outline: none;
    background-color: var(--dashBoardBg);
}

.notifications_card.card {
    border-radius: 15px;
}

.notifications_card .btn-delete {
    background: var(--deleteColor);
    color: #FFFFFF;
    border-radius: 15px;
}

.campaign-details .nav-tabs.nav_position .nav-link {
    color: #fff !important;
}

/*================ Notification Dropdown CSS ================*/
.custom_dropdown .notification {
    border-radius: 18px;
}

.custom_dropdown .dropdown .notification .noti-head {
    background: var(--cadmiumOrange) !important;
    border-radius: 15px 15px 0px 0px;
    padding: 20px;
}

.custom_dropdown .dropdown .notification .noti-footer {
    background: var(--royalBlueDark);
}

.custom_dropdown .dropdown .notification .noti-footer a {
    color: #fff;
}

.add-coupon-modal .input-group-append {
    position: absolute;
    right: 0;
}

.add-coupon-modal .input-group-append .input-group-text {
    background: none;
    border: none;
    padding: 15px 10px 0px;
}

.fan_list .data-table-extensions {
    padding-right: 0;
    right: 30px !important;
}

.view_all_plans .data-table-extensions {
    padding-right: 0;
    right: 30px !important;
}

.campaign-details .nav-tabs.nav_position .nav-link.active {
    background: #48a9f8;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 20%);
    border-radius: 32px;
    border-bottom: none;
    padding: 10px
}

.campaign-details .nav-tabs.nav_position .nav-link {
    background: #535763;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 20%);
    border-radius: 32px;
    padding: 10px;
}

.campaign-details .nav-tabs {
    border-bottom: none;
}

.campaign-details .nav_position {
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    font-style: normal;
}

/*.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a {*/
/*    font-weight: 500;*/
/*    color: var(--royalBlueDark);*/
/*}*/

/*.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:hover {*/
/*    color: #4680ff;*/
/*}*/
